import React, { useEffect, useState } from 'react';
import classes from './FormPage.module.css';
import InputMask from 'react-input-mask';
import PhoneService from '../../../Services/PhoneService';
import fall from '../../../images/fall.png';
import ok from '../../../images/active.png';

const FormPage2 = ({ isForm, setIsForm }) => {
  const [userPhone, setUserPhone] = useState('');
  const [userPhoneError, setUserPhoneError] = useState('');
  const [errPhone, setErrPhone] = useState();

  const requestPhone = async (e) => {
    e.preventDefault();

    if (!userPhone) {
      setUserPhoneError('Это поле обязательно для заполнения!');
      return;
    }
    let uPhone =
      userPhone[4] +
      userPhone[5] +
      userPhone[6] +
      userPhone[9] +
      userPhone[10] +
      userPhone[11] +
      userPhone[13] +
      userPhone[14] +
      userPhone[16] +
      userPhone[17];

    // for(let i = 0; i<)
    if (uPhone.indexOf('_') >= 0) {
      setUserPhoneError('Введён некорректный номер телефона!');
      return;
    } else {
      setUserPhoneError('');
    }
    // if (phone == null) {
    //   return;
    // }


    setIsForm(2);
    const response = await PhoneService.postGift(uPhone);
    response ? setIsForm(1) : setIsForm(3);
  };

  useEffect(() => {
    if (userPhone.length == 0) {
      setErrPhone(<img style={{top:"120px"}} src={fall} alt="" />);
      return;
    } else {
      setErrPhone(<img style={{top:"120px "}} src={fall} alt="" />);
    }
    if (userPhone.split('_').length === 1) {
      setErrPhone(<img style={{top:"120px "}} src={ok} alt="" />);
      setUserPhoneError('');
    }
  }, [userPhone]);

  return (
    <div className={classes.Page}>
      <div className={classes.container}>
        <div className={classes.content}>
          {isForm === 0 ? (
            <>
              <form className={'form-request'}>
                {/* <div style={{
                  color: "var(--Gray-1, #1F1F1F)",
                  textAlign: "center",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "120%",
                  fontFamily: 'Hauss Regular'

                }}>{"Не нашли номер? "}</div> */}
                {/* <div style={{
                  color: "var(--Gray-1, #1F1F1F)",
                  textAlign: "center",
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "120%",
                  fontFamily: 'Hauss Regular',
                  whiteSpace: "pre-wrap"
                }}>{"Оставьте заявку и получите 200₽ скидку на тариф!"}</div> */}
                <br/>
                
                <div style={{
                  marginTop:"8px",
                  color: "var(--Gray-1, #1F1F1F)",
                  textAlign: "center",
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "120%",
                  fontFamily: 'Hauss Regular',
                  whiteSpace: "pre-wrap"
                }}>{"Ваш телефон для связи!"}</div>

                <InputMask
                  value={userPhone}
                  onChange={(e) => setUserPhone(e.target.value)}
                  mask="+7 (999) 999-99-99"
                  type="phone"
                  placeholder="+7 (___) ___-__-__"
                  style={{ marginTop: '25px' }}
                  required={true}
                  className={classes.inputForm}
                />

                <div className={classes.inputError}>{userPhoneError}</div>
                <div className="error-img-phone">{errPhone}</div>
                <input
                    style={{marginTop:"15px"}}
                  onClick={requestPhone}
                  type="submit"
                  value="Отправить"
                  className={classes.button}
                />
              </form>
            </>
          ) : isForm === 1 ? (
            <div className={classes.responseForm}>
              Ваша форма отправлена оператору!
            </div>
          ) : isForm === 2 ? (
            <div className={classes.responseForm}>Отправка...</div>
          ) : (
            <div className={classes.responseForm}>
              Произошла ошибка, пожалуйста попробуйте отправить заявку позже.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormPage2;
