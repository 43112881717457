import axios from 'axios';
import phones from '../phones';

class PhoneService {

  static domain = "https://www.anncom.ru/dialer/lendingbezlimit/"
  static async getPhones() {

    const urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams);
    

    let response;

    await axios
      .get(
        this.domain + 'main.py?pattern=standard&user_id=' +
        localStorage.getItem('user_id') +
        '&tariff=1000'
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => { });

    return response;
  }

  static async getPhonesRate(price) {
    let response;

    await axios
      .get(
        this.domain + 'main.py?pattern=phones&user_id=' +
        localStorage.getItem('user_id') +
        '&tariff=' +
        price
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => { });

    return response?.data?.response?.items;
  }

  static async getPhonesRateNumber(price, number) {
    let response;
    await axios
      .get(
        this.domain + 'main.py?pattern=phones&user_id=' +
        localStorage.getItem('user_id') +
        '&tariff=' +
        price +
        '&phone_pattern=NNNNNNN' +
        number.toString() +
        number.toString() +
        number.toString()
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => { });

    return response?.data?.response?.items;
  }

  static async getAllRatesPhones(rates, isNumber = false, number = 0) {
    let list = [];
    let phones;
    let rateObg = {
      rate: {},
      phones: [],
    };

    for (const rate of rates) {
      rateObg = {};

      rateObg.rate = {
        tariffPrice: rate.tariffPrice,
        min: rate.min,
        gb: rate.gb,
        sms: rate.sms,
      };

      let phones;

      if (isNumber)
        phones = await this.getPhonesRateNumber(rate.tariffPrice, number);
      else phones = await this.getPhonesRate(rate.tariffPrice);

      rateObg.phones = phones;

      list.push(rateObg);
    }

    return list;
  }

  static async getAllRatesPhonesSlider(rates) {
    let list = [];
    let phones;
    let rateObg = {
      rate: {
        text: '',
      },
      phones: [],
    };

    for (const rate of rates) {
      rateObg.rate.text = rate.tariffPrice + ' р./мес.';

      const phones = await this.getPhonesRate(rate.tariffPrice);

      rateObg.phones = [];

      for (const phone of phones) {
        rateObg.phones.push(phone.phone);

        if (rateObg.phones.length === 6) break;
      }

      list.push(rateObg);
    }

    return list;
  }


  static async getPhonesPhone(userId=0, page=0) {
    let response;

    await axios
      .get(
          this.domain+'proxy.py?page='+page.toString()          
      )
      .then((res) => {
        response = res;
        console.log(res);
        
      })
      .catch((error) => {});
    return [...response?.data?.phone_numbers].map((item)=>{return {id:item, phone:item, tariff_price:790}});

    // let response = [];

    // await axios.get('https://api.store.bezlimit.ru/v2/phones', {
    //   params: {
    //     is_reserved: false,
    //     expand: "tariff",
    //     page: Math.floor(Math.random() * (50 - 1) + 1)
    //   },
    //   headers:{
    //     "Api-Token":localStorage.getItem("token_phone")
    //   }

    // })
    //   .then((res) => {
    //     // console.log(res);
        
    //     response = res?.data?.items?.map((item)=>{return {id:item?.phone,phone:item?.phone, tariff_price:item?.tariff?.price}});
    //   })
    //   .catch(async (error) => { 
    //     if(error.response.status===401){
    //       let formData = new FormData()
    //       formData.append("login", "9865558655")
    //       formData.append("password", "KmJ")
    //       await axios.post("https://api.store.bezlimit.ru/v2/users/login", formData).then((res)=>{
    //         // console.log(res)
    //         localStorage.setItem("token_phone", res?.data?.token)
    //       }).catch((error)=>{

    //       })
          
    //     }
    //   });

    //   return response;

    // return phones.sort(() => Math.random() - 0.5);
  }

  static async postForm(phone, name="not name", userPhone) {

    let urlParamsResult = []
    const urlParams = new URLSearchParams(window.location.search);
     urlParams.forEach((value,key)=> {
      urlParamsResult.push({name:key, value:value})
    })

    let response;
    let str = '';
    str += '?user_id=' + localStorage.getItem('user_id');

    if (phone) {
      str += '&phone_take=' + phone.phone;
      str += '&phone_tariff=' + phone.tariff_price;
      str += '&phone_id=' + phone.id;
    }
    str += '&cli_name=' + name;
    str += '&cli_phone=' + userPhone;
    str += '&mnt=' + JSON.stringify(urlParamsResult);

    await axios
      .get(
        this.domain + 'registration_number_lending.py' +
        str
      )
      .then((res) => {
        console.log(res);
        if(res.data.toString().includes("Код 422")){
          response = 422;
          console.log("false");
          
        }
        else response = 200;
      })
      .catch((error) => {
        response = 500;
      });

    return response;
  }

  static async postFormCol(phone) {
    let urlParamsResult = []
  const urlParams = new URLSearchParams(window.location.search);
   urlParams.forEach((value,key)=> {
    urlParamsResult.push({name:key, value:value})
  })
    let response;
    let str = '';
    str += '?id=' + localStorage.getItem('user_id');
    str += '&user_phone=' + phone;
    str += '&mnt=' + JSON.stringify(urlParamsResult);

    await axios
      .get(
        this.domain + 'registration_number_call_my.py' +
        str
      )
      .then((res) => {
        response = true;
      })
      .catch((error) => {
        response = false;
      });

    return response;
  }

  static async postGift(phone) {
    let urlParamsResult = []
  const urlParams = new URLSearchParams(window.location.search);
   urlParams.forEach((value,key)=> {
    urlParamsResult.push({name:key, value:value})
  })
    let response;
    let str = '';
    str += '?id=' + localStorage.getItem('user_id');
    str += '&user_phone=' + phone;
    str += '&mnt=' + JSON.stringify(urlParamsResult);

    await axios
      .get(
        this.domain + 'registration_number_present_vip.py' +
        str
      )
      .then((res) => {
        response = true;
      })
      .catch((error) => {
        response = false;
      });

    return response;
  }

  static async postFormFeedback(name, region, message) {
    let urlParamsResult = []
  const urlParams = new URLSearchParams(window.location.search);
   urlParams.forEach((value,key)=> {
    urlParamsResult.push({name:key, value:value})
  })
    let response;
    let str = '';
    str += '?user_id=' + localStorage.getItem('user_id');
    str += '&name=' + name;
    str += '&region=' + region;
    str += '&message=' + message;
    str += '&mnt=' + JSON.stringify(urlParamsResult);

    await axios
      .get(
        this.domain + 'registration_number_feedback.py' +
        str
      )
      .then((res) => {
        response = true;
      })
      .catch((error) => {
        response = false;
      });

    return response;
  }
}

export default PhoneService;
