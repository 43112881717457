import React, { useContext } from 'react';
import classes from './TwelvePage.module.css';
import vkD from './../../../images/TwelvePage/vkD.png';
import tg from './../../../images/TwelvePage/tg.png';
import img1 from './../../../images/TwelvePage/img1.png';
import { GlobalContext } from '../../../contexts/GlobalContext';

const TwelvePage = () => {
  const { content } = useContext(GlobalContext);

  // console.log(content);

  return (
    <div className={classes.TwelvePage}>
      <div className={classes.content}>
        <div className={classes.title}>{content?.twelve_1}</div>

        <div className={classes.blockTextM}>{content?.twelve_m_2}</div>

        <div className={classes.blockTextM}>{content?.twelve_m_3}</div>

        <div className={classes.blockText1}>{content?.twelve_2}</div>

        <div className={classes.Map}>
          <div style={{ position: 'relative', overflow: 'hidden' }}>
            <a
              href="https://yandex.ru/maps/213/moscow/?utm_medium=mapframe&utm_source=maps"
              style={{
                color: '#eee',
                fontSize: '12px',
                position: 'absolute',
                top: '0px',
              }}
            >
              {'Москва'}
            </a>
            <a
              href="https://yandex.ru/maps/213/moscow/house/1_y_magistralny_tupik_5a/Z04YcwRhQEADQFtvfXt3d31gZQ==/?ll=37.531954%2C55.766132&utm_medium=mapframe&utm_source=maps&z=17.14"
              style={{
                color: '#eee',
                fontSize: '12px',
                position: 'absolute',
                top: '14px',
              }}
            >
              {'1-й Магистральный тупик, 5А'}
            </a>
            <iframe
              src="https://yandex.ru/map-widget/v1/?ll=37.531954%2C55.766132&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1NjcyNzY0MxJL0KDQvtGB0YHQuNGPLCDQnNC-0YHQutCy0LAsIDEt0Lkg0JzQsNCz0LjRgdGC0YDQsNC70YzQvdGL0Lkg0YLRg9C_0LjQuiwgNdCQIgoNuSAWQhWFEF9C&z=17.14"
              width="100%"
              height="178px"
              frameBorder="1"
              // allowFullScreen="true"
              style={{ position: 'relative' }}
            ></iframe>
          </div>
        </div>

        <div className={classes.MapD}>
          <div style={{ position: 'relative', overflow: 'hidden' }}>
            <a
              href="https://yandex.ru/maps/213/moscow/?utm_medium=mapframe&utm_source=maps"
              style={{
                color: '#eee',
                fontSize: '12px',
                position: 'absolute',
                top: '0px',
              }}
            >
              Москва
            </a>
            <a
              href="https://yandex.ru/maps/213/moscow/house/1_y_magistralny_tupik_5a/Z04YcwRhQEADQFtvfXt3d31gZQ==/?ll=37.531954%2C55.766132&utm_medium=mapframe&utm_source=maps&z=17.14"
              style={{
                color: '#eee',
                fontSize: '12px',
                position: 'absolute',
                top: '14px',
              }}
            >
              1-й Магистральный тупик, 5А
            </a>
            <iframe
              src="https://yandex.ru/map-widget/v1/?ll=37.531954%2C55.766132&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1NjcyNzY0MxJL0KDQvtGB0YHQuNGPLCDQnNC-0YHQutCy0LAsIDEt0Lkg0JzQsNCz0LjRgdGC0YDQsNC70YzQvdGL0Lkg0YLRg9C_0LjQuiwgNdCQIgoNuSAWQhWFEF9C&z=17.14"
              width="100%"
              height="234px"
              frameBorder="1"
              // allowFullScreen="true"
              style={{ position: 'relative' }}
            ></iframe>
          </div>
        </div>

        <div className={classes.blockText2}>{content?.twelve_3}</div>

        <div className={classes.blockText3}>{content?.twelve_4}</div>

        <div className={classes.email}>
          <a href="">{content?.twelve_m_1}</a>
        </div>

        <div className={classes.links}>
          <a href="https://t.me/bezlimitofficial_tg">
            {' '}
            <img src={tg} alt="" />
          </a>
          <a href={content.twelve_link_vk_1} style={{ marginLeft: '10px' }}>
            <img src={vkD} alt="" />
          </a>
        </div>

        {/*Десктоп компоненты*/}

        <div className={classes.blockInfo}>
          <div className={classes.lineInfo}>
            <div className={classes.lineInfoLeft}>{content?.twelve_d_1}</div>
            <div className={classes.lineInfoRight1}>
              <a href={content.twelve_link_bezlimit_1}>{content?.twelve_d_2}</a>
            </div>
          </div>
          <div className={classes.lineInfo}>
            <div className={classes.lineInfoLeft}>{content?.twelve_d_3}</div>
            <div className={classes.lineInfoRight}>{content?.twelve_d_4}</div>
          </div>
        </div>

        <img src={img1} alt="yy" className={classes.img1} />

        <div className={classes.icons}>
          <a href="https://vk.com/bezlimit.official" target="_blank">
            <img src={vkD} alt="" />
          </a>
          <a href="https://t.me/bezlimitofficial_tg" target="_blank">
            <img
              src={tg}
              style={{ marginLeft: '25px', width: '72px' }}
              alt=""
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default TwelvePage;
