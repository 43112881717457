import { useState } from 'react'
import classes from './PointsPage.module.css'

const PointsPage = () => {

    const [points, setPoints] = useState([
        {
            region: 'Махачкала',
            points: [
                { text: 'Офис Безлимит, Проспект Акушинского, 395 (4-й этаж)', link: "https://yandex.ru/maps/-/CDsYz0pL" },
                { text: 'ТЦ "Этажи", просп. Имама Шамиля, 71, просп. Гамидова, 61 (вход в ТЦ с пр.Гамидова)', link: "https://yandex.ru/maps/-/CDsENVO6" },
                { text: 'Зеленое Яблоко "CITI", просп. Имама Шамиля, 33 ', link: "https://yandex.ru/maps/-/CDsEJX66" },
                { text: 'Зеленое Яблоко "Автодом", просп. Петра 1, 56', link: "https://yandex.ru/maps/-/CDsEN4I9" },
                { text: 'Зеленое Яблоко "Северный", Газопроводная ул., 2А', link: "https://yandex.ru/maps/-/CDsEvVL3" },
                { text: 'Зеленое Яблоко, просп. Али-Гаджи Акушинского, 130', link: "https://yandex.ru/maps/-/CDsERK1x" },
                { text: 'Зеленое Яблоко "Молоток", ул. Каммаева, 93', link: "https://yandex.ru/maps/-/CDsEvDKe" },
                { text: 'Зеленое Яблоко "Апельсин", просп. Насрутдинова, 56', link: "https://yandex.ru/maps/-/CDsEvP~y" },
                { text: 'ТЦ "Ардар" ул. Малыгина, 35 (временно закрыто)', link: "https://yandex.ru/maps/-/CDsEVK4h" },
                { text: 'Бытовая техника "Техноклимат", ул. Азизова, 27А', link: "https://yandex.ru/maps/-/CDsYrD~b" },
                { text: 'Салон связи "Apple ru", ул. Магомеда Ярагского, 71', link: "https://yandex.ru/maps/-/CDsEZQ4q" },
                { text: 'Салон связи "ProStor", ул. Магомеда Ярагского, 63', link: "https://yandex.ru/maps/-/CDsEZZkn" },
                { text: 'Дом Виски, проспект Гамидова, 55В', link: "https://yandex.ru/maps/-/CDsEZ0nj" },
                // { text: 'Гастроном "Сицилия", ул. Гагарина, 48', link: "https://yandex.ru/maps/-/CDsEZPP7" },
                { text: 'Салон связи, ул. Гамидова 49 к. 4', link: "https://yandex.ru/maps/-/CDsYvJ56" },
                
            ]
        },
        {
            region: 'Каспийск',
            points: [
                { text: 'ТЦ "Арадеш", ул. Амет-хан Султана, 11 (3-й этаж)', link: "https://yandex.ru/maps/-/CDsIEO1h" },
                { text: 'ТЦ "Паприка", ул. Ленина, 27А', link: "https://yandex.ru/maps/-/CDsEbN3M" },
                { text: 'ТЦ "Volna", ул. Ленина, 35А', link: "https://yandex.ru/maps/-/CDsEbS8M" },
                { text: 'АС Престиж, ул. М. Халилова,12', link: "https://yandex.ru/maps/-/CDsEbPIz" },
            ]
        },
        {
            region: 'Хасавюрт',
            points: [
                { text: 'ТЦ "Арбат", ул. Гамидова, 70', link: "https://yandex.ru/maps/-/CDsEfE5F" },
            ]
        },

        {
            region: 'Кизляр',
            points: [
                { text: 'Магазин бытовой техники Техно 05, ул. Фрунзе, 14', link: "https://yandex.ru/maps/-/CDsEfYP4" },
            ]
        },

        // {
        //     region: 'Кизилюрт',
        //     points: [
        //         { text: 'Салон связи, ул . Полежаева, 17А', link: "https://go.2gis.com/slcdq" },
        //     ]
        // },

        {
            region: 'Юсуп',
            points: [
                { text: 'Чеченская Республика, Урус-Мартан, улица имени Ахмат-Хаджи Кадырова, 84. Контактный телефон: +7 968 959-99-95', link: "https://yandex.ru/maps/-/CDsEjM0a" },
            ]
        },

        {
            region: 'Апти',
            points: [
                { text: 'Чеченская Республика, Грозный, Улица И.А. Арсаханова, 2. Контактный телефон: +7 963 222-46-48', link: "https://yandex.ru/maps/-/CDsErR3C" },
            ]
        },

        {
            region: 'Нальчик',
            points: [
                { text: 'ТЦ Среда, ул.Чернышевского, 177', link: "https://yandex.ru/maps/-/CDsErD0w" },
            ]
        },

        {
            region: 'Санкт-Петербург',
            points: [
                { text: 'ТЦ "Шайба", проспект Стачек, 66', link: "https://yandex.ru/maps/-/CDsIIHP-" },
            ]
        },
    ])

    return (
        <div className={classes.PointsPage}>
            <div className={classes.title}>
                Официальные точки продаж
            </div>
            <div className={classes.regions}>
                {
                    points?.map((region) => {
                        return (
                            <div className={classes.region} key={region.region}>
                                <div className={classes.regionTitle}>
                                    {region.region}
                                </div>
                                <div className={classes.regionPoints}>
                                    {
                                        region.points?.map((point) => {
                                            return (
                                                <a href={point.link} key={point.link}>
                                                    <div className={classes.regionPoint}>
                                                        {point.text}
                                                    </div>
                                                </a>

                                            )
                                        })
                                    }

                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
}

export default PointsPage